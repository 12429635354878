
import React, { useEffect } from 'react'
import Paper from '@mui/material/Paper';
import { Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { format } from 'date-fns'

function TablePage(dados) {
   
    useEffect(() => {
        console.log(dados);
    }, [dados]);

    function localHandler(issue){
        if (issue.customfield_10078 != null) {
            return issue.customfield_10078.value;
        } else {
            if (issue.customfield_10079 != null) {
                return issue.customfield_10079.value;
            } else {
                if (issue.customfield_10080 != null) {
                    return issue.customfield_10080.value;
                } else {
                    if (issue.customfield_10081 != null) {
                        return issue.customfield_10081.value;
                    } else {
                        if (issue.customfield_10082 != null) {
                            return issue.customfield_10082.value
                        } else{
                            return "";
                        }
                    }
                }
            }
        }
    }

    function especialidadeHandler(issue){
        if(issue.customfield_10229 != null) {
            return issue.customfield_10229.value;
        } else {
            return "";
        }
    }

  return (
    <TableContainer component={Paper} elevation={20}>
        <Table sx={{ minWidth: 300 }} size="small" aria-label="a dense table">
            <TableHead>
                <TableRow >
                    <TableCell>Data</TableCell>
                    <TableCell  align="right">Tipo OS</TableCell>
                    <TableCell  align="right">Nº OS</TableCell>
                    <TableCell  align="right">Status</TableCell>
                    <TableCell  align="right">Prioridade</TableCell>
                    <TableCell  align="right">Resumo</TableCell>
                    <TableCell  align="right">Especialidade</TableCell>
                    <TableCell  align="right">Local</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {dados.dados.map((issue) => (
                    <TableRow
                    key={issue.key}
                    sx={{ '&:last-child td, &:last-child th': {border:0} }}>
                        <TableCell component="th" scope="row">{ issue.fields.created }</TableCell>
                        <TableCell align="right">{issue.fields.issuetype.name}</TableCell>
                        <TableCell align="right">{issue.key}</TableCell>
                        <TableCell align="right">{issue.fields.status.name}</TableCell>
                        <TableCell align="right">{issue.fields.priority.name}</TableCell>
                        <TableCell align="right">
                            <Link underline="none" href={`https://pcdfsigeos.atlassian.net/servicedesk/customer/portal/2/${issue.key}`}>
                            {issue.fields.summary}
                            </Link>
                        </TableCell>
                        <TableCell align="right">{especialidadeHandler(issue.fields)}</TableCell>
                        <TableCell align="right">{localHandler(issue.fields)}</TableCell>
                       
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
  )
}

export default TablePage