import axios from 'axios'

export const getFiltered = async (param) => {
        const {especialidade, issuetype, status, complexo, edificio} = param; 

        const startAt = 0;
        const maxResults = 100;  
        const url = "https://backend-sigeos.herokuapp.com/sigeos/cloud/search/";
        //const url = "http://localhost:8092/sigeos/cloud/search/";

        var query = "resolution = Unresolved";
        
        if(especialidade != ''){
            if(especialidade.length > 1) {
                query=query.concat(" AND ( ");
                for(var j = 0 ; j < especialidade.length ; j++) {
                    if (j == 0) {
                        query=query.concat("Especialidade = '"+especialidade[j] + "'");
                    } else {
                        query=query.concat(" OR Especialidade = '"+especialidade[j] + "'");
                    }
                    
                }
                query=query.concat(" ) ");
            } else {
                query=query.concat(" AND Especialidade = '"+ especialidade.toString() + "'");
            }
        }

        if(issuetype != ''){
            if(issuetype.length > 1) {
                query=query.concat(" AND ( ");
                for(var j = 0 ; j < issuetype.length ; j++) {
                    if (j == 0) {
                        query=query.concat("issuetype = '"+issuetype[j] + "'");
                    } else {
                        query=query.concat(" OR issuetype = '"+issuetype[j] + "'");
                    }
                    
                }
                query=query.concat(" ) ");
            } else {
                query=query.concat(" AND issuetype = '"+ issuetype.toString() + "'");
            }
        }

        if(status != ''){
            if(status.length > 1) {
                query=query.concat(" AND ( ");
                for(var j = 0 ; j < status.length ; j++) {
                    if (j == 0) {
                        query=query.concat("status = '"+status[j] + "'");
                    } else {
                        query=query.concat(" OR status = '"+status[j] + "'");
                    }
                    
                }
                query=query.concat(" ) ");
            } else {
                query=query.concat(" AND status = '"+ status.toString() + "'");
            }
        }

        if(complexo != ''){
            query = query.concat( " AND COMPLEXO = '" + complexo + "'" );
           
           if(edificio != ''){
                switch(complexo){
                    case 'COMPLEXO PCDF': 
                        query = query.concat(" AND 'EDIFÍCIOS COMPLEXO PCDF' = '" + edificio + "'");
                        break;

                    case 'COMPLEXO DASG/DITRAN': 
                        query = query.concat(" AND 'EDIFÍCIOS COMPLEXO DASG/DITRAN' = '" + edificio + "'");
                        break;

                    case 'COMPLEXO REGIONAL NORTE': 
                        query = query.concat(" AND 'EDIFÍCIOS COMPLEXO REGIONAL NORTE' = '" + edificio + "'");
                        break;

                    case 'UNIDADES DIVERSAS': 
                        query = query.concat(" AND 'EDIFÍCIOS COMPLEXO UNIDADES DIVERSAS' = '" + edificio + "'");
                        break;

                    case 'COMPLEXO SGON': 
                        query = query.concat(" AND 'EDIFÍCIOS COMPLEXO SGON' = '" + edificio + "'");
                        break;

                    default: break;
                }
           }
        }
/*
        const data = {
            jql: query,
            maxResults: 100,
            startAt: 0
        }
        //url: 'https://backend-sigeos.herokuapp.com/sigeos/cloud/search/',
        console.log(query);
        var config = {
            method: 'post',
            url: 'https://backend-sigeos.herokuapp.com/sigeos/cloud/search/',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data,
        };
        return axios(config);*/
    return await axios.post(url, {jql:query, startAt:startAt, maxResults:maxResults});
}


export const getOsFiltered  = async (param) => {
    if (param.shift == '' && param.start == '' && param.end == ''  && param.muliple == '' && param.issuetype == ''){
        return null;
    } else {
        var query = "status = Planejado";

        if (param.shift != '') {
            query=query.concat(" AND Turno = " + param.shift);
        }
        if (param.start != '') {
            query=query.concat(" AND 'Start date' >= " + param.start);
        }
        if (param.end != '') {
            query=query.concat(" AND 'End date' <= " +param.end);
        }
        if(param.issuetype != ''){
            if(param.issuetype.length > 1) {
                query=query.concat(" AND ( ");
                for(var j = 0 ; j < param.issuetype.length ; j++) {
                    if (j == 0) {
                        query=query.concat("issuetype = "+param.issuetype[j]);
                    } else {
                        query=query.concat(" OR issuetype = "+param.issuetype[j]);
                    }
                    
                }
                query=query.concat(" ) ");
            } else {
                query=query.concat(" AND issuetype = "+ param.issuetype.toString());
            }
        }
        if(param.multiple != ''){
            console.log(param.multiple.length);
            if(param.multiple.length > 1) {
                /**
                 * 2 
                 * Civil,Elétrica
                 * AND ( Equipe = Civil OR Equipe = Elétrica )
                 * 
                 * 3
                 * Civil,Elétrica,TI
                 * AND ( Equipe = Civil OR Equipe = Elétrica OR Equipe = TI )
                 */
                //console.log(param.multiple);
                 query=query.concat(" AND ( ");
                for(var i = 0 ; i < param.multiple.length ; i++) {
                    if (i == 0) {
                        query=query.concat("Equipe = "+param.multiple[i]);
                    } else {
                        query=query.concat(" OR Equipe = "+param.multiple[i]);
                    }
                    
                }
                 query=query.concat(" ) ");
            }else {
                console.log(param.multiple.toString());
                query=query.concat(" AND Equipe = "+ param.multiple.toString());
            }
            
        }

        query=query.concat(" order by 'Start Date'");

        console.log("DEBUG  " + query);

        const data = {
            jql: query,
        }

        var config = {
            method: 'post',
            url: 'http://rj.sigeos.com.br/rest/api/2/search',
            headers: {
                'Authorization': 'Basic YWRtaW46bWt0YjIwMTU=', 
                'Content-Type': 'application/json'
            },
            data: data,
        };
        return axios(config);
    }
}