import React from "react";
import { MenuItem, TextField, Grid, Chip } from "@mui/material";

function GridPcdf({ handler }, { edificio }) {
  return (
    <div>
      <Grid item xs={3}>
        <TextField
          label="Edifício"
          variant="outlined"
          value={edificio}
          style={{ minWidth: 130, maxWidth: 150 }}
          select
          onChange={handler}
          SelectProps={{
            multiple: false,
            renderValue: (selected) => (
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <Chip key={selected} label={selected} style={{ margin: 2 }} />
              </div>
            ),
          }}
        >
          <MenuItem value={"BRB"}>BRB</MenuItem>
          <MenuItem value={"CECOF"}>CECOF</MenuItem>
          <MenuItem value={"CENTRAL DE GUARDA E CUSTODIA DE VESTIGIOS"}>
            CENTRAL DE GUARDA E CUSTODIA DE VESTIGIOS
          </MenuItem>
          <MenuItem value={"CENTRO TECNOLÓGICO"}>CENTRO TECNOLÓGICO</MenuItem>
          <MenuItem value={"DAE - Divisão de Arquitetura e Engenharia"}>
            DAE - Divisão de Arquitetura e Engenharia
          </MenuItem>
          <MenuItem value={"DECOR"}>DECOR</MenuItem>
          <MenuItem value={"DPE - DEPARTAMENTO DE POLÍCIA ESPECIALIZADA"}>
            DPE - DEPARTAMENTO DE POLÍCIA ESPECIALIZADA
          </MenuItem>
          <MenuItem
            value={
              "DRFV - PÁTIO DE APOIO DIVISÃO DE CADASTRO DE ROUBOS E FURTOS DE VEÍCULOS"
            }
          >
            DRFV - PÁTIO DE APOIO DIVISÃO DE CADASTRO DE ROUBOS E FURTOS DE
            VEÍCULOS
          </MenuItem>
          <MenuItem value={"EDIFÍCIO SEDE"}>EDIFÍCIO SEDE</MenuItem>
          <MenuItem value={"GUARITA"}>GUARITA</MenuItem>
          <MenuItem value={"II - INSTITUTO DE IDENTIFICAÇÃO"}>
            II - INSTITUTO DE IDENTIFICAÇÃO
          </MenuItem>
          <MenuItem value={"IML - INSTITUTO DE MEDICINA LEGAL"}>
            IML - INSTITUTO DE MEDICINA LEGAL
          </MenuItem>
          <MenuItem value={"INSTITUTO DE CRIMINALÍSTICA"}>
            INSTITUTO DE CRIMINALÍSTICA
          </MenuItem>
          <MenuItem value={"IPDNA - INSTITUTO DE PESQUISA DE DNA FORENSE"}>
            IPDNA - INSTITUTO DE PESQUISA DE DNA FORENSE
          </MenuItem>
          <MenuItem value={"SIV - IC SEÇÃO DE IDENTIFICAÇÃO DE VEÍCULOS"}>
            SIV - IC SEÇÃO DE IDENTIFICAÇÃO DE VEÍCULOS
          </MenuItem>
        </TextField>
      </Grid>
    </div>
  );
}

export default GridPcdf;
