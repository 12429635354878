import {
  MenuItem,
  Paper,
  TextField,
  Grid,
  Chip,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { getFiltered } from "../api/api";
import TablePage from "./TablePage";
import GridDasg from "./Grid/GridDasg";
import GridPcdf from "./Grid/GridPcdf";
import GridRegional from "./Grid/GridRegional";
import GridSgon from "./Grid/GridSgon";
import GridUnidades from "./Grid/GridUnidades";
import Loading from "./Loading";

function Filtros() {
  const [form, setForm] = useState({
    especialidade: [],
    issuetype: [],
    status: [],
    complexo: '',
    edificio: '',
   
  });
  const [list, setList] = useState([]);

  const handleStatus = (event) => {
    setForm({ ...form, status: event.target.value });
  };
  const handleIssuetype = (event) => {
    setForm({ ...form, issuetype: event.target.value });
  };
  const handleEspecialidade = (event) => {
    setForm({ ...form, especialidade: event.target.value });
  };
  const handleComplexo = (event) => {
    setForm({ ...form, complexo: event.target.value });
  };
  const handleEdificio = (event) => {
    setForm({ ...form, edificio: event.target.value });
  };
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getFiltered(form)
    .then((result) => {
        setList(result.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
    
    
  }, [form]);

  return (
    <>
      <Paper elevation={3}>
        <Grid
          className="esquerda"
          container
          spacing={1}
          direction="row"
          justifyContent="center"
          alignItems="center"
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={3}>
            <TextField
              label="Especialidade"
              variant="outlined"
              value={form.especialidade}
              style={{ minWidth: 130 }}
              select
              onChange={handleEspecialidade}
              SelectProps={{
                multiple: true,
                renderValue: (selected) => (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} style={{ margin: 2 }} />
                    ))}
                  </div>
                ),
              }}
            >
              <MenuItem value={"Chaveiro"}>Chaveiro</MenuItem>
              <MenuItem value={"Civil - Alvenaria"}>Civil - Alvenaria</MenuItem>
              <MenuItem value={"Civil - Cobertura"}>Civil - Cobertura</MenuItem>
              <MenuItem value={"Civil - Demolição/Remoção"}>
                Civil - Demolição/Remoção
              </MenuItem>
              <MenuItem value={"Civil - Forro/divisória"}>
                Civil - Forro/divisória
              </MenuItem>
              <MenuItem value={"Civil - Impermeabilização"}>
                Civil - Impermeabilização
              </MenuItem>
              <MenuItem value={"Civil - Infraestrutura"}>
                Civil - Infraestrutura
              </MenuItem>
              <MenuItem value={"Civil - Outros"}>Civil - Outros</MenuItem>
              <MenuItem value={"Civil - Pintura"}>Civil - Pintura</MenuItem>
              <MenuItem value={"Civil - Revestimento"}>
                Civil - Revestimento
              </MenuItem>
              <MenuItem value={"Civil - Superestrutura"}>
                Civil - Superestrutura
              </MenuItem>
              <MenuItem value={"Marcenaria"}>Marcenaria</MenuItem>
              <MenuItem value={"Outros"}>Outros</MenuItem>
              <MenuItem value={"Prevenção e combate a incêndio"}>
                Prevenção e combate a incêndio
              </MenuItem>
              <MenuItem value={"Refrigeração"}>Refrigeração</MenuItem>
              <MenuItem value={"Serralheria"}>Serralheria</MenuItem>
              <MenuItem value={"Sistema elétrico"}>Sistema elétrico</MenuItem>
              <MenuItem value={"Sistema hidráulico"}>
                Sistema hidráulico
              </MenuItem>
              <MenuItem value={"Transporte"}>Transporte</MenuItem>
              <MenuItem value={"Urbanização"}>Urbanização</MenuItem>
              <MenuItem value={"Vidros"}>Vidros</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={3}>
            <TextField
              label="Tipo de OS"
              variant="outlined"
              value={form.issuetype}
              style={{ minWidth: 130 }}
              select
              onChange={handleIssuetype}
              SelectProps={{
                multiple: true,
                renderValue: (selected) => (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} style={{ margin: 2 }} />
                    ))}
                  </div>
                ),
              }}
            >
              <MenuItem value={"Corretiva"}>Corretiva</MenuItem>
              <MenuItem value={"Preventiva"}>Preventiva</MenuItem>
              <MenuItem value={"Sob Demanda"}>Sob Demanda</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={3}>
            <TextField
              label="Status"
              variant="outlined"
              value={form.status}
              style={{ minWidth: 130 }}
              select
              onChange={handleStatus}
              SelectProps={{
                multiple: true,
                renderValue: (selected) => (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} style={{ margin: 2 }} />
                    ))}
                  </div>
                ),
              }}
            >
              <MenuItem value={"Aberto"}>Aberto</MenuItem>
              <MenuItem value={"Aprovado"}>Aprovado</MenuItem>
              <MenuItem value={"Em Orçamento"}>Em Orçamento</MenuItem>
              <MenuItem value={"Refazer Orçamento"}>Refazer Orçamento</MenuItem>
              <MenuItem value={"EM DESLOCAMENTO"}>Em Deslocamento</MenuItem>
              <MenuItem value={"Serviço Iniciado"}>Serviço Iniciado</MenuItem>
              <MenuItem value={"SERVIÇO FINALIZADO"}>
                Serviço Finalizado
              </MenuItem>
              <MenuItem value={"Orçamento Pós Serviço Iniciado"}>
                Orçamento Pós Serviço Iniciado
              </MenuItem>
              <MenuItem value={"Imprevisto"}>Imprevisto</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={3}>
            <TextField
              label="Complexo"
              variant="outlined"
              value={form.complexo}
              style={{ minWidth: 130, maxWidth: 150 }}
              select
              onChange={handleComplexo}
              SelectProps={{
                multiple: false,
                renderValue: (selected) => (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    
                      <Chip key={selected} label={selected} style={{ margin: 2 }} />
                    
                  </div>
                ),
              }}
            >
              <MenuItem value={"COMPLEXO PCDF"}>PCDF</MenuItem>
              <MenuItem value={"COMPLEXO REGIONAL NORTE"}>REGIONAL NORTE</MenuItem>
              <MenuItem value={"COMPLEXO SGON"}>SGON</MenuItem>
              <MenuItem value={"UNIDADES DIVERSAS"}>UNIDADES DIVERSAS</MenuItem>
              <MenuItem value={"COMPLEXO DASG/DITRAN"}>DASG/DITRAN</MenuItem>
            </TextField>
          </Grid>

          
           {form.complexo ==='COMPLEXO PCDF' && <GridPcdf handler={handleEdificio} edificio={form.edificio}/>}
           {form.complexo === 'COMPLEXO REGIONAL NORTE' && <GridRegional handler={handleEdificio} edificio={form.edificio}/>}
           {form.complexo === 'COMPLEXO SGON' && <GridSgon handler={handleEdificio} edificio={form.edificio}/>}
           {form.complexo === 'UNIDADES DIVERSAS' && <GridUnidades handler={handleEdificio} edificio={form.edificio}/>}
           {form.complexo === 'COMPLEXO DASG/DITRAN' && <GridDasg handler={handleEdificio} edificio={form.edificio}/>}


        </Grid>
      </Paper>

      <br />
      <br />
      <br />
      <div className="center">
      { loading && <Loading />}
      
      </div>
      <TablePage dados={list} />
    </>
  );
}

export default Filtros;
