import React from "react";
import { MenuItem, TextField, Grid, Chip } from "@mui/material";

function GridUnidades({ handler }, { edificio }) {
  return (
    <div>
      <Grid item xs={3}>
        <TextField
          label="Edifício"
          variant="outlined"
          value={edificio}
          style={{ minWidth: 130, maxWidth: 150 }}
          select
          onChange={handler}
          SelectProps={{
            multiple: false,
            renderValue: (selected) => (
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <Chip key={selected} label={selected} style={{ margin: 2 }} />
              </div>
            ),
          }}
        >
          <MenuItem value={"10ª DELEGACIA DE POLÍCIA - QI 05"}>
            10ª DELEGACIA DE POLÍCIA - QI 05
          </MenuItem>
          <MenuItem value={"11ª DELEGACIA DE POLÍCIA"}>
            11ª DELEGACIA DE POLÍCIA
          </MenuItem>
          <MenuItem value={"12ª DELEGACIA DE POLÍCIA"}>
            12ª DELEGACIA DE POLÍCIA
          </MenuItem>
          <MenuItem value={"13ª DELEGACIA DE POLÍCIA"}>
            13ª DELEGACIA DE POLÍCIA
          </MenuItem>
          <MenuItem value={"14ª DELEGACIA DE POLÍCIA"}>
            14ª DELEGACIA DE POLÍCIA
          </MenuItem>
          <MenuItem value={"15ª DELEGACIA DE POLÍCIA"}>
            15ª DELEGACIA DE POLÍCIA
          </MenuItem>
          <MenuItem value={"16ª DELEGACIA DE POLÍCIA"}>
            16ª DELEGACIA DE POLÍCIA
          </MenuItem>
          <MenuItem value={"17ª DELEGACIA DE POLÍCIA"}>
            17ª DELEGACIA DE POLÍCIA
          </MenuItem>
          <MenuItem value={"18ª DELEGACIA DE POLÍCIA"}>
            18ª DELEGACIA DE POLÍCIA
          </MenuItem>
          <MenuItem value={"19ª DELEGACIA DE POLÍCIA"}>
            19ª DELEGACIA DE POLÍCIA
          </MenuItem>
          <MenuItem value={"1ª DELEGACIA DE POLÍCIA"}>
            1ª DELEGACIA DE POLÍCIA
          </MenuItem>
          <MenuItem value={"20ª DELEGACIA DE POLÍCIA"}>
            20ª DELEGACIA DE POLÍCIA
          </MenuItem>
          <MenuItem value={"21ª DELEGACIA DE POLÍCIA"}>
            21ª DELEGACIA DE POLÍCIA
          </MenuItem>
          <MenuItem value={"23ª DELEGACIA DE POLÍCIA"}>
            23ª DELEGACIA DE POLÍCIA
          </MenuItem>
          <MenuItem value={"24ª DELEGACIA DE POLÍCIA"}>
            24ª DELEGACIA DE POLÍCIA
          </MenuItem>
          <MenuItem value={"26ª DELEGACIA DE POLÍCIA"}>
            26ª DELEGACIA DE POLÍCIA
          </MenuItem>
          <MenuItem value={"27ª DELEGACIA DE POLÍCIA"}>
            27ª DELEGACIA DE POLÍCIA
          </MenuItem>
          <MenuItem value={"29ª DELEGACIA DE POLÍCIA"}>
            29ª DELEGACIA DE POLÍCIA
          </MenuItem>
          <MenuItem value={"2ª DELEGACIA DE POLÍCIA"}>
            2ª DELEGACIA DE POLÍCIA
          </MenuItem>
          <MenuItem value={"30ª DELEGACIA DE POLÍCIA"}>
            30ª DELEGACIA DE POLÍCIA
          </MenuItem>
          <MenuItem value={"31ª DELEGACIA DE POLÍCIA"}>
            31ª DELEGACIA DE POLÍCIA
          </MenuItem>
          <MenuItem value={"32ª DELEGACIA DE POLÍCIA"}>
            32ª DELEGACIA DE POLÍCIA
          </MenuItem>
          <MenuItem value={"33ª DELEGACIA DE POLÍCIA"}>
            33ª DELEGACIA DE POLÍCIA
          </MenuItem>
          <MenuItem value={"35ª DELEGACIA DE POLÍCIA"}>
            35ª DELEGACIA DE POLÍCIA
          </MenuItem>
          <MenuItem value={"38ª DELEGACIA DE POLÍCIA"}>
            38ª DELEGACIA DE POLÍCIA
          </MenuItem>
          <MenuItem value={"3ª DELEGACIA DE POLÍCIA"}>
            3ª DELEGACIA DE POLÍCIA
          </MenuItem>
          <MenuItem value={"4ª DELEGACIA DE POLÍCIA"}>
            4ª DELEGACIA DE POLÍCIA
          </MenuItem>
          <MenuItem value={"5ª DELEGACIA DE POLÍCIA"}>
            5ª DELEGACIA DE POLÍCIA
          </MenuItem>
          <MenuItem value={"6ª DELEGACIA DE POLÍCIA"}>
            6ª DELEGACIA DE POLÍCIA
          </MenuItem>
          <MenuItem value={"8ª DELEGACIA DE POLÍCIA"}>
            8ª DELEGACIA DE POLÍCIA
          </MenuItem>
          <MenuItem value={"9ª DELEGACIA - PROVISÓRIA"}>
            9ª DELEGACIA - PROVISÓRIA
          </MenuItem>
          <MenuItem value={"CIOB"}>CIOB</MenuItem>
          <MenuItem value={"CPA - COMISSÃO PERMANENTE DE ALIENAÇÃO"}>
            CPA - COMISSÃO PERMANENTE DE ALIENAÇÃO
          </MenuItem>
          <MenuItem value={"DCA I - DELEGACIA DA CRIANÇA E DO ADOLESCENTE"}>
            DCA I - DELEGACIA DA CRIANÇA E DO ADOLESCENTE
          </MenuItem>
          <MenuItem value={"DCA II - DELEGACIA DA CRIANÇA E DO ADOLESCENTE II"}>
            DCA II - DELEGACIA DA CRIANÇA E DO ADOLESCENTE II
          </MenuItem>
          <MenuItem
            value={"DEAM I - DELEGACIA ESPECIAL DE ATENDIMENTO À MULHER"}
          >
            DEAM I - DELEGACIA ESPECIAL DE ATENDIMENTO À MULHER
          </MenuItem>
          <MenuItem value={"DOE - DIVISÃO DE OPERAÇÕES ESPECIAIS SIA"}>
            DOE - DIVISÃO DE OPERAÇÕES ESPECIAIS SIA
          </MenuItem>
          <MenuItem
            value={"ESPC - Escola Superior de Polícia Civil - Riacho Fundo II"}
          >
            ESPC - Escola Superior de Polícia Civil - Riacho Fundo II
          </MenuItem>
          <MenuItem
            value={"ESPC - Escola Superior de Polícia Civil - Taguatinga"}
          >
            ESPC - Escola Superior de Polícia Civil - Taguatinga
          </MenuItem>
          <MenuItem value={"POLICLÍNICA"}>POLICLÍNICA</MenuItem>
          <MenuItem value={"POSTO POLICIAL DO AEROPORTO"}>
            POSTO POLICIAL DO AEROPORTO
          </MenuItem>
        </TextField>
      </Grid>
    </div>
  );
}

export default GridUnidades;
