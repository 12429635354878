import React from "react";
import { MenuItem, TextField, Grid, Chip } from "@mui/material";

function GridSgon({ handler }, { edificio }) {
  return (
    <div>
      <Grid item xs={3}>
        <TextField
          label="Edifício"
          variant="outlined"
          value={edificio}
          style={{ minWidth: 130, maxWidth: 150 }}
          select
          onChange={handler}
          SelectProps={{
            multiple: false,
            renderValue: (selected) => (
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <Chip key={selected} label={selected} style={{ margin: 2 }} />
              </div>
            ),
          }}
        >
          <MenuItem value={"CANIL"}>CANIL</MenuItem>
          <MenuItem
            value={"DAME - DIVISÃO DE CONTROLE DE ARMAS MUNIÇÕES E EXPLOSIVOS"}
          >
            DAME - DIVISÃO DE CONTROLE DE ARMAS MUNIÇÕES E EXPLOSIVOS
          </MenuItem>
          <MenuItem value={"DOA - DIVISÃO DE OPERAÇÕES AÉREAS"}>
            DOA - DIVISÃO DE OPERAÇÕES AÉREAS
          </MenuItem>
          <MenuItem
            value={"DPCI - DIVISÃO DE CAPTURAS E POLÍCIA INTERESTADUAL"}
          >
            DPCI - DIVISÃO DE CAPTURAS E POLÍCIA INTERESTADUAL
          </MenuItem>
          <MenuItem value={"DRM - DIVISÃO DE RECURSOS E MATERIAIS"}>
            DRM - DIVISÃO DE RECURSOS E MATERIAIS
          </MenuItem>
        </TextField>
      </Grid>
    </div>
  );
}

export default GridSgon;
