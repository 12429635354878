import React from "react";
import { MenuItem, TextField, Grid, Chip } from "@mui/material";

function GridRegional({ handler }, { edificio }) {
  return (
    <div>
      <Grid item xs={3}>
        <TextField
          label="Edifício"
          variant="outlined"
          value={edificio}
          style={{ minWidth: 130, maxWidth: 150 }}
          select
          onChange={handler}
          SelectProps={{
            multiple: false,
            renderValue: (selected) => (
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <Chip key={selected} label={selected} style={{ margin: 2 }} />
              </div>
            ),
          }}
        >
          <MenuItem value={"DCB - DIVISÃO DE CUSTÓDIA DE BENS"}>
            DCB - DIVISÃO DE CUSTÓDIA DE BENS
          </MenuItem>
          <MenuItem value={"GUARITA"}>GUARITA</MenuItem>
        </TextField>
      </Grid>
    </div>
  );
}

export default GridRegional;
