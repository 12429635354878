import { Container } from '@mui/material';
import './App.css';
import Filtros from './components/Filtros';

function App() {
  return (
    <Container maxWidth="xl" className="descer">
      <Filtros />
    </Container>
  );
}

export default App;
